.container {
    overflow-x: hidden;
    overflow-y: scroll;
    height: 90vh;
    overflow: auto;
    /* scrollbar-width: thin; */
    scrollbar-color: transparent transparent;
}

.container::-webkit-scrollbar {
    width: 0px;
    background-color: transparent;
}

.container::-webkit-scrollbar-thumb {
    background-color: transparent;
}