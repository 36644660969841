/* Accordion.module.css */

.accordion {
    display: flex;
    flex-direction: column;
}

.accordion-tab {

    padding: 1.5rem;
    margin-bottom: 1rem;
    background-color: white;
    border-radius: 15px;
    cursor: pointer;
    font-size: 1.5rem;
    font-weight: 600;
    color: black;
}


.accordion-tab.active {
    background-color: var(--grey-100);
    /* border-bottom: 1px solid var(--grey-400); */
    border-radius: 0px;
    padding-bottom: 0;
}

.accordion-content {
    padding-top: 0 !important;
    padding: 1.5rem;
    background-color: var(--grey-100);
    /* margin-bottom: 10px; */
}

.accordion-tab.inactive {
    color: grey;
    cursor: default;
}

.accordion-content.visible {
    display: block;
}

.accordion-content.hidden {
    display: none;
}