/* thead {
  background-color: #e1e1e1;
  color: black;
  font-size: 1rem;
*/

th {
  font-weight: 700
}


.header {
  display: flex;
  align-items: center;
  background-color: var(--primary-500);
  padding: 10px;
  position: absolute;
  top: 0;
  left: 0;
}

.title {
  font-size: 2rem;
  font-weight: 700;
  margin: 0;
  margin-left: 10px;

}

.labels {
  font-size: 1.5rem;
  font-weight: 700;
  margin: 3%;
}

.subTitle {
  font-size: 1.7rem;
  font-weight: 700;
  margin: 1% 0;
  color: black;
  text-transform: uppercase;
}

.searchBar {
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: 500;
  width: 360px;
  height: 5vh;
  border-radius: 10px;
  background-color: transparent;
  border: 1px solid #a7a7a7;
  padding: 2px;
  margin: 3% 0;
}

.searchInput {
  flex: 1;
  border: none;
  outline: none;
  background-color: transparent;
  padding: 5px;
}

.searchIcon {
  color: #a7a7a7;
  margin: 10px;
}

.logout {
  display: flex;
  align-items: center;
  margin-right: 10px;
  cursor: pointer;
}

.logoutIcon {
  color: #a7a7a7;
  margin-right: 5px;
}

.rowStyle {
  color: black;
  font-weight: 700;
  height: 3.5vw;
  vertical-align: middle !important;
}

.tableRow {
  border-bottom: 1px solid var(--grey-100) !important;
}

.tableHeaders {
  font-weight: 700
}

.daysAgoStyle {
  font-weight: 100;
  color: var(--grey-600);
}