@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
@import url('./colors.css');

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0px;
}

html,
body,
#root {
    margin: 0;
    height: fit-content;
    width: 100%;
    /* overflow: auto; */
    font-weight: 450;
    font-size: 14px;
    font-family: 'Ubuntu', sans-serif;
}

::-webkit-scrollbar {
    width: 10px;
}

/*TRACK*/
::-webkit-scrollbar-track {
    background: #e0e0e0;
}

/* HANDLE */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.text-default-black {
    color: var(--default-black);
}