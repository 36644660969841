.container {
    max-width: 20vw;
    display: flex;
    min-Width: 200px;
}

.inputGroup {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 15px 10px -7px;
    border-radius: 50px;
    flex: 1;

}

.button {
    border-radius: "0 50px 50px 0";
    border-color: "var(--primary-500)";
    background-color: "var(--primary-500)";
    color: "black";
    font-size: "bold";
}