:root {
    /* --primary-400: #f8eaa6;
    --primary-500: #f0d348;
    --primary-600: #ddc134;
    --primary-700: #c9ab2f;
    --primary-800: #b5962a;
    --primary-900: #a18225; */

    --primary-300: #4c7fff;
    --primary-400: #2658ff;
    --primary-500: #002FD0;
    --primary-600: #00249c;
    --primary-700: #001968;
    --primary-800: #000e34;
    --primary-900: #000400;


    --grey-100: #EEEEEE;
    --grey-200: #E0E0E0;
    --grey-300: #c9c7c7;
    --grey-400: #bdbdbd;
    --grey-500: #9e9e9e;
    --grey-600: #404040;

    --white: #fff;
    --black: #000;
    --blue: #0f2c74;

    --newStatus: #FF4A78;
    --inProgressStatus: #f0d348;
    --doneStatus: #000000;

    --default-black: #0A0A0A;
}